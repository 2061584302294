import { ICreatorMessage } from 'app/(app)/creator.typings';
import { Badge, Note, Text } from '@ui/components';
import { FanIcon } from '@components/fan-icon';
import { getBadgeInfo } from '@ui/lib/utils';

interface ICreatorMessagesListItem {
  message: ICreatorMessage;
  onOpenMessage(item: ICreatorMessage): void;
}

export function CreatorMessagesListItem({ message, onOpenMessage }: ICreatorMessagesListItem) {
  function onClick() {
    onOpenMessage(message);
  }
  const info = getBadgeInfo(message?.richDescription);
  return (
    <div
      onClick={onClick}
      className="hover:bg-bgGrey active:bg-korLightOrange flex cursor-pointer justify-between gap-3 rounded-3xl p-3 transition-colors">
      <div className="flex items-center gap-3 overflow-hidden">
        <div
          className="bg-border flex h-[44px] w-[44px] items-center justify-center rounded-full"
          style={{
            background: message.isRead
              ? ''
              : 'linear-gradient(153deg, #56CCF2 16.67%, #D356F2 100%)',
          }}>
          {info ? (
            <div className="h-[44px] w-[44px] overflow-hidden rounded-full">
              <Badge
                type={info?.type}
                image={info?.image}
                color={info?.color}
                size="xxxs"
                className="h-full w-full scale-[1.35]"
                showSerialNumber={false}
              />
            </div>
          ) : (
            <FanIcon type="chat" />
          )}
        </div>
        <div className="flex w-[100px] flex-col overflow-hidden text-ellipsis md:w-[300px]">
          <Text type="m" className="line-clamp-1 truncate whitespace-pre-wrap font-semibold">
            {message.title}
          </Text>
          <Note type="s" className="line-clamp-1 truncate whitespace-pre-wrap">
            {message.message}
          </Note>
        </div>
        <div
          className={`mx-2 h-min rounded-lg bg-[#FDC] px-1.5 py-1 ${
            message.isRead && 'invisible'
          }`}>
          <Text type="xs" className="text-primary break-normal font-bold uppercase">
            New
          </Text>
        </div>
      </div>

      <Note type="xs" className="break-normal">
        {message.date}
      </Note>
    </div>
  );
}
