import { Message, Title, MobileHeader, MobileHeaderContent } from '@ui/components';
import { ICreator, ICreatorMessage } from 'app/(app)/creator.typings';

interface ICreatorMessageItem {
  creator: ICreator;
  message: ICreatorMessage;
  onClose(): void;
}

export function CreatorMessageItem({ creator, message, onClose }: ICreatorMessageItem) {
  return (
    <div className="flex h-full w-full flex-col bg-white lg:rounded-l-3xl">
      <MobileHeader className="lg:flex">
        <MobileHeaderContent title={creator.name} onBack={onClose} />
      </MobileHeader>
      <div className="flex flex-grow flex-col overflow-y-auto rounded-t-3xl bg-black lg:flex-1">
        <div className="flex items-center justify-center gap-4 p-8">
          <Title type="sm" className="w-full text-center text-white">
            {message.title}
          </Title>
        </div>
        <div className="grounded-t-3xl flex flex-grow overflow-y-auto">
          <Message
            avatar={creator.avatar}
            image={message.image}
            title={message.title}
            message={message.message}
            richDescription={message?.richDescription}
            innerClassName="px-4 pt-12 overflow-y-auto rounded-t-3xl"
            wrapperClassName="overflow-y-auto rounded-t-3xl"
            messageClassName="pb-12"
          />
        </div>
      </div>
    </div>
  );
}
