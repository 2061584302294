import {
  messagesAdapter,
  messagesSelector,
  useGetMessagesQuery,
  useReadMessageMutation,
} from '@lib/redux/api/messages-api';
import { ICreator, ICreatorMessage } from 'app/(app)/creator.typings';
import { CreatorMessagesList } from './creator-messages-list';
import { useEffect, useState } from 'react';
import { CreatorMessageItem } from './creator-message-item';
import { ModalWrapper } from '@ui/components';
import { sendGAEvent } from '@next/third-parties/google';
import { useSearchParams } from 'next/navigation';

interface ICreatorMessages {
  isOpen: boolean;
  creator: ICreator;
  onClose(): void;
}

export function CreatorMessages({ creator, isOpen, onClose }: ICreatorMessages) {
  const [page, setPage] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [prevCreator, setPrevCreator] = useState(null);

  const params = useSearchParams();
  const messageId = params.get('messageId');

  const {
    data,
    isSuccess,
    isFetching,
    isLoading: isMessagesLoading,
    isUninitialized,
    refetch,
  } = useGetMessagesQuery(
    {
      creatorId: creator.id,
      page: creator.id === prevCreator ? page : 0,
      pageSize: 10,
    },
    {
      selectFromResult({ data, ...params }) {
        return {
          data: {
            ...data,
            messages: messagesSelector.selectAll(
              data ? data.messages : messagesAdapter.getInitialState(),
            ),
          },
          ...params,
        };
      },
    },
  );

  useEffect(() => {
    if (prevCreator !== creator.id && !isUninitialized && !isMessagesLoading) {
      setPage(0);
      setPrevCreator(creator.id);
      refetch();
    }
  }, [creator.id]);

  const [readMessageRequest] = useReadMessageMutation();

  function onOpenMessage(message: ICreatorMessage) {
    if (!message.isRead) {
      readMessageRequest({
        params: {
          creatorId: creator.id,
          page,
          pageSize: 10,
        },
        id: message.id,
      });
    }
    setMessage(message);
    sendGAEvent('event', 'MessageOpened', { creatorId: creator.id, messageId: message.id });
  }

  useEffect(() => {
    if (messageId && data?.messages) {
      const message = data?.messages?.find((message) => message?.id === messageId);
      message && onOpenMessage(message);
    }
  }, [messageId, isSuccess]);

  useEffect(() => {
    if (page === 0 && isFetching) {
      return setLoading(true);
    }
    if (isSuccess) {
      setLoading(false);
    }
  }, [isFetching, isSuccess, page]);

  function onNextPage() {
    setPage(page + 1);
  }
  const hasMore = isSuccess && data && !isMessagesLoading ? page + 1 <= data.pages : false;

  const [message, setMessage] = useState<ICreatorMessage | null>(null);

  function onCloseMessage() {
    setMessage(null);
  }

  return (
    <ModalWrapper isOpen={isOpen} type="side">
      {message ? (
        <CreatorMessageItem creator={creator} message={message} onClose={onCloseMessage} />
      ) : isSuccess && data ? (
        <CreatorMessagesList
          creator={creator}
          onClose={onClose}
          messages={data.messages}
          isLoading={isLoading}
          onOpenMessage={onOpenMessage}
          hasMore={hasMore}
          onNextPage={onNextPage}
        />
      ) : null}
    </ModalWrapper>
  );
}
