import { cookieService } from '@lib/cookie.service';

interface IAuthSessionState {
  isAuthenticated: boolean;
  isLoading: boolean;
}

export const useAuthSession = (): IAuthSessionState => {
  const { accessToken } = cookieService.getAuthCookies();

  return {
    isAuthenticated: !!accessToken,
    isLoading: false,
  };
};
