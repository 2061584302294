import { Avatar, EmptyScreen, Title, MobileHeader, MobileHeaderContent } from '@ui/components';
import InfiniteScroll from 'react-infinite-scroll-component';

import { ICreator, ICreatorMessage } from 'app/(app)/creator.typings';

import { CreatorMessagesListItem } from './creator-messages-list-item';
import { useId } from 'react';
import { CreatorMessageLoader } from './creator-message-loader';

interface ICreatorMessagesList {
  creator: ICreator;
  messages: ICreatorMessage[];
  hasMore: boolean;
  isLoading: boolean;
  onNextPage(): void;
  onClose(): void;
  onOpenMessage(message: ICreatorMessage): void;
}

export function CreatorMessagesList({
  creator,
  messages,
  hasMore,
  isLoading,
  onClose,
  onOpenMessage,
  onNextPage,
}: ICreatorMessagesList) {
  const id = useId();

  function onNext() {
    onNextPage();
  }

  return (
    <div className="flex h-full w-full flex-col bg-white lg:rounded-l-xl">
      <MobileHeader className="lg:flex">
        <MobileHeaderContent title="Messages" onBack={onClose} />
      </MobileHeader>
      <div className="flex flex-col overflow-y-auto rounded-t-xl bg-black lg:flex-1">
        <div className="flex items-center justify-center gap-1 p-8">
          <div className="w-max">
            <Avatar size="sm" src={creator.avatar} />
          </div>
          <Title type="sm" className="text-center text-white">
            {creator.name}
          </Title>
        </div>
        <div id={id} className="flex-grow overflow-y-auto rounded-t-xl bg-white">
          {isLoading ? (
            <div className="flex flex-col gap-4 p-5">
              <CreatorMessageLoader />
              <CreatorMessageLoader />
              <CreatorMessageLoader />
            </div>
          ) : messages.length ? (
            <InfiniteScroll
              className="flex flex-col gap-1 p-5"
              dataLength={messages.length}
              next={onNext}
              hasMore={hasMore}
              scrollableTarget={id}
              loader={<CreatorMessageLoader />}>
              {messages.map((message, key) => (
                <CreatorMessagesListItem
                  message={message}
                  key={key}
                  onOpenMessage={onOpenMessage}
                />
              ))}
            </InfiniteScroll>
          ) : (
            <EmptyScreen title="You have no messages yet." message="" />
          )}
        </div>
      </div>
    </div>
  );
}
