import { useCallback, useEffect, useState } from 'react';
import {
  IConversation,
  ICreateMessageThread,
  IMessage,
  IMessagePayload,
  IMessagesQueryParams,
  IReportMessage,
  useToast,
} from '@ui/components';
import { useAppDispatch, useAppSelector } from '@lib/redux/store';
import { TViewState, VIEW_STATE } from '@ui/constants';
import {
  messagesV2Adapter,
  messagesV2Selector,
  useCreateMessageMutation,
  useCreateMessageThreadMutation,
  useDeleteMessageMutation,
  useGetMessageByIdQuery,
  useGetMessagesOfThreadQuery,
  useReactToMessageMutation,
  useReportMessageMutation,
  useUpdateMessageMutation,
  useShareMessageMutation,
} from '@lib/redux/api/messages-api-v2';
import { useGetEngagementScoreQuery } from '@lib/redux/api/engagement-api';
import { fansAdapter, fansSelector } from '@lib/redux/api/leaderboard-api';
import { IFile } from '@ui/hooks/file/types';
import { usePostImageMutation } from '@lib/redux/api/messages-api';
import { debounce } from 'lodash';
import {
  autocompleteAdapter,
  autocompleteSelector,
  IAutocompleteQueryParam,
} from '@autocomplete/service';
import { useGetUserSuggestionsQuery } from '@lib/redux/api/autocomplete-api';
import {
  useCreateNotificationMutation,
  useUpdateNotificationMutation,
  useGetNotificationByTypeQuery,
  useMarkNotificationsByTypeMutation,
} from '@lib/redux/api/notification-api';
import { useGetPublicProfileQuery } from '@lib/redux/api/user-api';
import {
  CREATOR_COMMUNITY_ROUTES,
  CREATOR_WEB_URL,
  FAN_COMMUNITY_ROUTES,
  FAN_WEB_URL,
} from '@notification/service';
import { setCreatorId } from '@lib/redux/features/user';
import { useAuthSession } from '@lib/hooks/use-auth-session';
const pageItemsNumber = 12;
const initialState: IMessagesQueryParams = {
  thread_id: '',
  creator_id: '',
  conversation_id: '',
  pageSize: pageItemsNumber,
  page: 1,
  fan_id: '',
};

interface Props {
  ordering: IMessagesQueryParams['ordering'];
  messageId?: string | null;
  main_post_ref?: string | null;
}
export function useCreatorMessagesState({ ordering, messageId, main_post_ref }: Props): any {
  const { isAuthenticated } = useAuthSession();
  const toast = useToast();
  const [selectedMessage, setSelectedMessage] = useState<IMessage | null>(null);
  const { user } = useAppSelector((state) => state.user);
  const [query, setQuery] = useState<IMessagesQueryParams>({
    ...initialState,
    ordering,
  });
  const [viewState, setViewState] = useState<TViewState>('list');
  const dispatch = useAppDispatch();
  const {
    data,
    isLoading,
    isSuccess,
    isFetching,
    isError: isMessagesError,
    error: messagesError,
  } = useGetMessagesOfThreadQuery(query, {
    skip:
      !query.thread_id ||
      !query.creator_id ||
      !query.conversation_id ||
      (isAuthenticated && !user.id),
    selectFromResult: ({ data, ...params }) => {
      return {
        data: {
          ...data,
          messages: messagesV2Selector.selectAll(
            data ? data.messages : messagesV2Adapter.getInitialState(),
          ),
        },
        ...params,
      };
    },
  });
  const [reactToMessage] = useReactToMessageMutation();
  const [reportMessage] = useReportMessageMutation();
  const [createNotification] = useCreateNotificationMutation();
  const [updateNotification] = useUpdateNotificationMutation();
  const [messageToEdit, setMessageToEdit] = useState<IMessagePayload | null>(null);
  const [markNotifications] = useMarkNotificationsByTypeMutation();
  const [conversationMiscellaneous, setConversationMiscellaneous] = useState<
    IConversation['miscellaneous'] | null
  >(null);
  const {
    data: singleMessageData,
    isLoading: isLoadingSingleMessage,
    isSuccess: isSingleMessageSuccess,
    isError: isSingleMessageError,
    error: singleMessageError,
  } = useGetMessageByIdQuery(messageId, {
    skip: !messageId,
  });
  const { data: newPostNotifications } = useGetNotificationByTypeQuery(
    { user_id: user.id, type: 'post', creator_id: query?.creator_id },
    { skip: !user.id || !query?.creator_id },
  );
  const {
    data: mainPostRefData,
    isLoading: isLoadingMainPostRef,
    isSuccess: isMainPostRefSuccess,
    isError: isMainPostRefError,
    error: mainPostRefError,
  } = useGetMessageByIdQuery(main_post_ref, {
    skip: !main_post_ref,
  });
  const [postImage, { isLoading: isPostingImage }] = usePostImageMutation();
  const [createMessage, { isLoading: isCreating }] = useCreateMessageMutation();
  const [updateMessage, { isLoading: isUpdatingMessage, isError: isUpdateMessageError }] =
    useUpdateMessageMutation();
  const [createMessageThread] = useCreateMessageThreadMutation();
  const [deleteMessage] = useDeleteMessageMutation();
  const [sharePost] = useShareMessageMutation();
  const { data: creatorProfile } = useGetPublicProfileQuery(query.creator_id, {
    skip: !query.creator_id,
  });
  const [userSearchQuery, setUserSearchQuery] = useState<IAutocompleteQueryParam>({
    stage: 'random',
    userId: user?.id,
    creator_id: creatorProfile?.id,
  });
  const {
    data: autocompleteData,
    isLoading: isLoadingSuggestions,
    isFetching: isFetchingSuggestions,
  } = useGetUserSuggestionsQuery(
    {
      ...userSearchQuery,
      userId: user.id,
      creator_id: creatorProfile?.id || userSearchQuery.creator_id,
    },
    {
      skip: !user.id || !userSearchQuery.creator_id || !isAuthenticated,
      selectFromResult: ({ data, ...params }) => {
        return {
          data: {
            ...data,
            suggestions: autocompleteSelector.selectAll(
              data ? data.suggestions : autocompleteAdapter.getInitialState(),
            ),
          },
          ...params,
        };
      },
    },
  );

  const markNotificationsByType = useCallback(
    async ({ user_id, type, creator_id }) => {
      try {
        const result = await markNotifications({ user_id, type, creator_id }).unwrap();
        return result.success || false;
      } catch (error) {
        console.error('Error marking notifications as read:', error);
        return false;
      }
    },
    [newPostNotifications?.unread_count],
  );

  const onNextPage = () => setQuery((prev) => ({ ...prev, page: prev.page + 1 }));
  const onSetCreator = (creator_id: string) => {
    setQuery((prev) => ({ ...prev, creator_id }));
    setUserSearchQuery((prev) => ({ ...prev, creator_id }));
  };
  const onSetFan = (fan_id: string) => setQuery((prev) => ({ ...prev, fan_id }));
  const onSetThread = (thread_id: string) => setQuery((prev) => ({ ...prev, thread_id }));
  const onSetConversation = (conversation_id: string) =>
    setQuery((prev) => ({ ...prev, conversation_id }));

  const onReactToMessage = async (
    message_id: string,
    slug: string,
    action: 'reaction' | 'downvote' | 'upvote',
    value: number | string,
    params: IMessagesQueryParams,
  ) => {
    try {
      await reactToMessage({ message_id, slug, [action]: value, params, user_id: user.id });
    } catch (error) {
      console.error('Error reacting to message:', error);
    }
  };

  const onReportMessage = async (payload: IReportMessage) => {
    try {
      await reportMessage(payload);
      toast({ title: 'Reported', message: 'Message has been reported.' }, { type: 'success' });
    } catch (error) {
      console.error('Error reporting message:', error);
    }
  };

  const resetQuery = () => setQuery(initialState);
  const onSetConversationMiscellaneous = (miscellaneous: IConversation['miscellaneous'] | null) =>
    setConversationMiscellaneous(miscellaneous);

  const uploadImages = async (images: IFile[]) => {
    try {
      // Post files
      const files: IFile[] = [];
      if (images) {
        for (const image of images) {
          if (image.preventUpload) {
            files.push({
              ...image,
              meta: { ...image.meta, source: image.src },
            });
          } else if (image.file) {
            const res = await postImage(image.file).unwrap();
            files.push({
              ...image,
              meta: { ...image.meta, source: res },
            });
          }
        }
      }

      return files;
    } catch (error) {
      throw error;
    }
  };

  const createMentionNotification = async (message: IMessagePayload, messageSlug: string) => {
    const fanCommunityContentRouteMap = FAN_COMMUNITY_ROUTES.community;
    const creatorCommunityContentRouteMap = CREATOR_COMMUNITY_ROUTES.community;

    try {
      const mentionsWithFanIDsOnly = Array.from(new Set(message.mentions)).filter(
        (m) => m !== user.id && m !== singleMessageData.creator_id,
      );
      const mentionsWithCreatorIDOnly = message.mentions.find(
        (m) => m === singleMessageData.creator_id,
      );

      // Notifications for fan mentions
      if (mentionsWithFanIDsOnly.length > 0) {
        await createNotification({
          fan_ids: mentionsWithFanIDsOnly,
          creator_id: user.id,
          post_owner_id: singleMessageData.creator_id,
          recipients: 'ids',
          send_email: true,
          miscellaneous: {
            link: FAN_WEB_URL,
            year: new Date().getFullYear(),
            suffixLabel: `mentioned you in a ${message.type}`,
            routeLabel: `/${creatorProfile.displayName}'s Community`,
            createdFrom: message.type,
            mentionedBy: {
              userName: user.username,
              profileURL: user.avatar,
            },
            route: `${FAN_WEB_URL}${
              fanCommunityContentRouteMap[message.type]
            }/?slug=${messageSlug}&main_post_ref=${message.main_post_ref}`,
          },
        });
      }

      // Notifications for creators mentions
      if (mentionsWithCreatorIDOnly) {
        await createNotification({
          fan_ids: [mentionsWithCreatorIDOnly],
          creator_id: user.id,
          post_owner_id: singleMessageData.creator_id,
          recipients: 'ids',
          send_email: false,
          miscellaneous: {
            link: CREATOR_WEB_URL,
            year: new Date().getFullYear(),
            suffixLabel: `mentioned you in a ${message.type}`,
            routeLabel: `/${creatorProfile.displayName}'s Community`,
            createdFrom: message.type,
            mentionedBy: {
              userName: user.username,
              profileURL: user.avatar,
            },
            route: `${CREATOR_WEB_URL}${
              creatorCommunityContentRouteMap[message.type]
            }/?slug=${messageSlug}&main_post_ref=${message.main_post_ref}`,
          },
        });
      }
    } catch (error) {
      console.error('Error creating notification:', error);
    }
  };
  const onCreateMessage = async (message: IMessagePayload) => {
    try {
      const files = await uploadImages(message.files.images);
      const messageRes = await createMessage({ ...message, files: { images: files } });
      await createMentionNotification(message, messageRes.data?.message.id);

      return messageRes;
    } catch (error) {
      throw error;
    }
  };

  const onSharePost = async (message: string) => {
    await sharePost(message);
  };

  const onUpdateMessage = async (message: IMessagePayload, query: IMessagesQueryParams) => {
    try {
      const files = await uploadImages(message.files.images);
      const messageRes = await updateMessage({
        ...message,
        files: { images: files },
        params: query,
      });
      await createMentionNotification(message, messageRes.data?.id);
      return messageRes;
    } catch (error) {
      throw error;
    }
  };

  const onCreateMessageThread = async (payload: ICreateMessageThread) => {
    try {
      return await createMessageThread(payload);
    } catch (error) {
      console.error('Error creating message thread:', error);
    }
  };

  const onDelete = async (message: IMessage, type?: 'post' | 'comment' | 'reply') => {
    if (user.id === message.user_id) {
      await deleteMessage({ ...query, id: message.id, type });
    }
  };

  const debouncedSetUserSearchQuery = useCallback(
    debounce(
      (query: IAutocompleteQueryParam) => setUserSearchQuery((state) => ({ ...state, ...query })),
      0,
    ),
    [],
  );
  const hasMorePages = isSuccess && data ? query.page < data.totalPages : false;

  const { data: engagementScoreData } = useGetEngagementScoreQuery(
    { fan_id: user.id, creator_id: query.creator_id },
    { skip: !query.creator_id || !user?.id },
  );

  useEffect(() => {
    query?.creator_id && dispatch(setCreatorId(query?.creator_id));
  }, [query?.creator_id]);

  return {
    status:
      isLoading && query.page === 1
        ? VIEW_STATE.loading
        : isSuccess && !!data.messages.length
        ? VIEW_STATE.list
        : VIEW_STATE.empty,
    onNextPage,
    selectedMessage,
    setSelectedMessage,
    messages: isSuccess ? data.messages : [],
    pinned_messages: isSuccess ? data.pinnedMessages : [],
    viewState,
    isLoading,
    isFetching,
    isSuccess,
    setViewState,
    fansSelector,
    fansAdapter,
    user_id: user.id,
    user_username: user.username,
    user_avatar_url: user.avatar,
    creator_info: user.creatorInfo,
    creator_id: singleMessageData ? singleMessageData.creator_id : null,
    onSetCreator,
    onSetThread,
    onSetConversation,
    onSetFan,
    onReactToMessage,
    onReportMessage,
    isCreator: false,
    isCreating,
    query,
    resetQuery,
    communityPostRoute: FAN_COMMUNITY_ROUTES.community.post,
    communityCommentRoute: FAN_COMMUNITY_ROUTES.community.comment,
    communityReplyRoute: FAN_COMMUNITY_ROUTES.community.reply,
    onSetConversationMiscellaneous,
    conversationMiscellaneous,
    singleMessage: singleMessageData ? singleMessageData : null,
    mainPostRef: mainPostRefData ? mainPostRefData : null,
    isSingleMessageError,
    singleMessageError,
    isSingleMessageSuccess,
    isLoadingSingleMessage,
    onCreateMessage,
    onCreateMessageThread,
    useGetMessagesOfThreadQuery,
    onSharePost,
    messagesSelector: messagesV2Selector,
    messagesAdapter: messagesV2Adapter,
    onDelete,
    onUpdateMessage,
    isMessagesError,
    messagesError,
    hasMorePages,
    suggestions: autocompleteData.suggestions,
    debouncedSetUserSearchQuery,
    isLoadingSuggestions: isLoadingSuggestions || isFetchingSuggestions,
    isLoadingMainPostRef,
    isMainPostRefSuccess,
    isMainPostRefError,
    mainPostRefError,
    score: engagementScoreData,
    updateNotification,
    newPostNotifications,
    isPostingImage,
    isUpdatingMessage,
    isUpdateMessageError,
    messageToEdit,
    setMessageToEdit,
    markNotificationsByType,
  };
}
